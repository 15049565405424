// State initial object
import {getUserInfo} from "@/services/api/requests"

const initialState = () => ({
	id: null,
	email: null,
	department: null,
	position: null,
	number: null,
	daysAvailable: null,
	role: null,
	firstName: null,
	lastName: null,
	middleName: null,
	viewPermissions: [],
	credentials: {
		login: "",
		password: "",
		newPassword: "",
		confirmationPassword: "",
		captcha: "",
		code: "",
	}
})


/* Module .store.js */


// VUEX STATE
const state = initialState()


// VUEX GETTERS
const getters = {
	isLoggedIn: (state) => !!state.email,
	getId: (state) => state.id,
	getUser: (state) => state,
	getDepartment: (state) => state.department,
	getDaysAvailable: (state) => state.daysAvailable,
	getJobPosition: (state) => state.position,
	getPersonalNumber: (state) => state.number,
	getRole: (state) => state.role,
	getName: (state) => state.name,
	getEmail: (state) => state.email,
	getPermissions: (state) => state.viewPermissions,
	getLogin: (state) => state.login,
	getPassword: (state) => state.password,
	getCredentialsLogin: (state) => state.credentials.login,
	getCredentialsPassword: (state) => state.credentials.password,
	getCredentialsNewPassword: (state) => state.credentials.newPassword,
	getCredentialsConfirmationPassword: (state) => state.credentials.confirmationPassword,
	getCredentialsCode: (state) => state.credentials.code
}


// VUEX ACTIONS
const actions = {
	reset({commit}) {
		commit("RESET")
	},
	async init({ commit }) {
		commit("SET", await getUserInfo())
	},
}


// VUEX MUTATIONS
const mutations = {
	RESET(state) {
		const newState = initialState()
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	SET(state, profile) {
		state.email = profile.email
		state.id = profile.id
		state.department = profile.department
		state.position = profile.position
		state.number = profile.number
		state.daysAvailable = profile.days_available
		state.firstName = profile.first_name
		state.lastName = profile.last_name
		state.middleName = profile.middle_name
		state.role = profile.role
		state.viewPermissions = profile.user_view_permissions || []
		state.viewPermissions = state.viewPermissions.map(({value})=>value)
	},
	LOGIN(state, payload) {
		state.credentials = {
			...state.credentials,
			login: payload
		}
	},
	PASSWORD(state, payload) {
		state.credentials = {
			...state.credentials,
			password: payload
		}
	},
	NEW_PASSWORD(state, payload) {
		state.credentials = {
			...state.credentials,
			newPassword: payload
		}
	},
	CONFIRMATION_PASSWORD(state, payload) {
		state.credentials = {
			...state.credentials,
			confirmationPassword: payload
		}
	},
	CODE(state, payload) {
		state.credentials = {
			...state.credentials,
			code: payload
		}
	},
}


export default {
	state,
	getters,
	actions,
	mutations
}